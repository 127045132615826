import React from 'react'
//import {useIntl} from "gatsby-plugin-intl"
import Layout from '../components/layout'
import Header from '../components/header'


const EngineeringLeistungen = () => (
  <Layout>
    
    <Header 
      title='Engineering Leistungen'
      text=''
    />
    
    <div className='row'
    style={{
      margin: '0 auto',
         
    }}>
        <div className='col-12'>

        <p>Für die industrielle Anwendung konstruieren und bauen wir hochwertige FDM-Drucker. Hochwertige Bauteile und eine Industriesteuerung liefern sehr präzise Drucke. Durch die eigene Konstruktion können wir die Drucker genau Ihren Anderungen gemäß planen und bauen</p>
        </div>

    </div>

    

    </Layout>
)


export default EngineeringLeistungen;